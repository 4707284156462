import { Button } from '@pinecorpca/spruce';
import styled from 'styled-components';

const StyledCashbackPromotion = {
  Container: styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    background: ${({ theme }) => theme.background.secondary};
    border: 1px solid ${({ theme }) => theme.border.primary};
    border-radius: 8px;
    padding: 24px;
    width: fit-content;
    max-width: 500px;
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  Title: styled.h2`
    font-family: var(--font-tiempos);
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.text.primary};
  `,
  InterestedTitle: styled.h2`
    font-family: var(--font-tiempos);
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.text.primary};
  `,
  Description: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.text.secondary};
  `,
  InterestedButton: styled(Button)`
    margin-top: 10px;
    width: 142px;
  `,
};

export default StyledCashbackPromotion;
