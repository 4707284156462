import React, { FunctionComponent, useState } from 'react';
import noop from 'lodash/noop';
import { ShortAppCompleteDto } from '@pinecorpca/evergreen';

export type ShortAppBody = ShortAppCompleteDto & {
  cashbackIncentive?: boolean;
};

export type ShortAppContextType = {
  body: ShortAppBody;
  updateBody: (body: ShortAppBody) => void;
  replaceBody: (body: ShortAppBody) => void;
};

export const ShortAppContext = React.createContext<ShortAppContextType>({
  body: {},
  updateBody: () => noop,
  replaceBody: () => noop,
});

export const useShortAppContext = () => React.useContext(ShortAppContext);
export const ShortAppProvider: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const [body, setBody] = useState({});
  return (
    <ShortAppContext.Provider
      value={{
        body,
        updateBody: (requestBody: ShortAppBody) => setBody((prev: ShortAppBody) => ({ ...prev, ...requestBody })),
        replaceBody: (requestBody: ShortAppBody) => setBody(requestBody),
      }}
    >
      {children}
    </ShortAppContext.Provider>
  );
};
