import { AddressDto } from '@pinecorpca/evergreen';

/* eslint-disable camelcase */

type AddressType = Omit<AddressDto, 'unitNumber' | 'streetDirection' | 'addressLine2' | 'id' | 'monthlyRent' | 'district'>;

const addressValueMapper: Record<keyof AddressType, string[]> = {
  streetNumber: ['street_number'],
  postalCode: ['postal_code'],
  streetName: ['street_address', 'route'],
  province: ['administrative_area_level_1'],
  city: ['locality', 'sublocality', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3', 'sublocality_level_4'],
  country: ['country'],
};

export const parseAddressComponent = (addressComponent: google.maps.GeocoderAddressComponent[] | undefined) =>
  addressComponent?.reduce((acc, { long_name, types }) => {
    const type = types[0];
    const objKey = Object.keys(addressValueMapper).find((key: string) =>
      addressValueMapper[key as keyof AddressType].includes(type) ? key : undefined
    );

    if (!objKey) return acc;

    return {
      ...acc,
      [objKey]: long_name,
    };
  }, {} as AddressType);
