import { ReactNode, useCallback } from 'react';
import { ToastOptions, TypeOptions, toast } from 'react-toastify';
import { faCircleCheck, faCircleExclamation, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

export const useToast = () => {
  const { text, palette } = useTheme();

  const getToastIcon = useCallback(
    (type: TypeOptions | undefined) => {
      switch (type) {
        case 'error':
          return <FontAwesomeIcon data-testid="toast-error" icon={faCircleExclamation} size="xl" color={text.error} />;
        case 'warning':
          return <FontAwesomeIcon data-testid="toast-warning" icon={faTriangleExclamation} size="xl" color={palette.wheat.bright} />;
        default:
          return <FontAwesomeIcon data-testid="toast-success" icon={faCircleCheck} size="xl" color={palette.moss.bright} />;
      }
    },
    [text.error, palette]
  );

  const notify = useCallback(
    (message: ReactNode, options?: ToastOptions<any> | undefined) =>
      toast.success(message, {
        icon: getToastIcon(options?.type),
        hideProgressBar: true,
        autoClose: 3600,
        position: toast.POSITION.BOTTOM_CENTER,
        ...options,
      }),
    [getToastIcon]
  );

  const dismiss = useCallback(() => toast.dismiss(), []);

  return {
    notify,
    dismiss,
  };
};
