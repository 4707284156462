export enum API {
  ADDRESS = 'ADDRESS_HISTORY',
  ADDITIONAL_PROPERTY = 'ADDITIONAL_PROPERTY',
  MORTGAGE_APPLICATION = 'MORTGAGE_APPLICATION',
  ENTITIES = 'ENTITIES',
  ASSETS = 'ASSETS',
  BORROWER = 'BORROWER',
  DOWNPAYMENT = 'DOWNPAYMENT',
  INCOMES = 'INCOMES',
  LOS = 'LOS',
  MULTIPLE = 'MULTIPLE',
  PROPERTY = 'PROPERTY',
  USER = 'USER',
  CREDIT_CONSENT = 'CREDIT_CONSENT',
  TASKS = 'TASKS',
  PROGRESS_STEPS = 'PROGRESS_STEPS',
  DOCUMENTS = 'DOCUMENTS',
  BEST_RATES = 'BEST_RATES',
  SERVICING = 'SERVICING',
  WS_TIERS = 'WS_TIERS',
  BEST_RATES_REBATE = 'BEST_RATES_REBATE',
  CASHBACK = 'CASHBACK',
  INCENTIVES = 'INCENTIVES',
}
