import { API } from '@models/enums/api.enum';
import {
  BorrowerControllerApiApiBorrowersBorrowerIdDeleteRequest as BorrowerDeleteRequest,
  BorrowerControllerApiApiBorrowersBorrowerIdPatchRequest as BorrowerPatchRequest,
  BorrowerControllerApiApiMortgageApplicationsApplicationIdBorrowersPostRequest as BorrowerPostRequest,
  BorrowerControllerApiApiBorrowersBorrowerIdInvitePostRequest as BorrowerInviteRequest,
  BorrowerType,
  MortgageApplicationClientDto,
  BorrowerClientDto,
} from '@pinecorpca/evergreen';
import { useMemo } from 'react';
import { borrowerApi } from 'api/evergreen';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMortgageAppQuery } from './mortgage-application.hooks';
import { useUserQuery } from './user.hooks';
import { useRouter } from 'next/router';

export const useBorrowerQuery = (borrowerId?: string) => {
  const { data: user } = useUserQuery();
  const {
    data: application,
    isSuccess,
    isLoading,
    isFetching,
  } = useMortgageAppQuery(({ borrowers, id }: MortgageApplicationClientDto) => ({
    borrowers,
    id,
  }));

  const borrower = useMemo<BorrowerClientDto>(
    () =>
      borrowerId
        ? (application?.borrowers?.find((borrower) => borrower.id === borrowerId) as BorrowerClientDto)
        : (application?.borrowers?.find((borrower) => borrower.userId === user?.id) as BorrowerClientDto),
    [application?.borrowers, user, borrowerId]
  );

  const primaryBorrower = useMemo(
    () => application?.borrowers?.find(({ borrowerType }) => borrowerType === BorrowerType.Primary),
    [application?.borrowers]
  );

  return {
    isSuccess,
    data: borrower,
    primaryBorrower,
    isLoading,
    isFetching,
    borrowers: application?.borrowers,
  };
};

export const useBorrowerMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: BorrowerPostRequest) => borrowerApi.apiMortgageApplicationsApplicationIdBorrowersPost(payload),
    onSuccess: ({ data: borrower }) => {
      queryClient.setQueryData(
        [API.MORTGAGE_APPLICATION, applicationId as string],
        (current: MortgageApplicationClientDto | undefined) => ({
          ...current,
          borrowers: [...(current?.borrowers || []), borrower],
        })
      );
    },
  });
};

export const useBorrowerMutationOnPatch = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: BorrowerPatchRequest) => borrowerApi.apiBorrowersBorrowerIdPatch(payload),
    onSuccess: ({ data: updatedBorrower }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId], (current: MortgageApplicationClientDto | undefined) => ({
        ...current,
        borrowers: current?.borrowers?.map((borrower) =>
          borrower.id === updatedBorrower.id ? { ...borrower, ...updatedBorrower } : borrower
        ),
      }));
    },
  });
};

export const useBorrowerMutationOnDelete = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (request: BorrowerDeleteRequest) => borrowerApi.apiBorrowersBorrowerIdDelete(request),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [API.MORTGAGE_APPLICATION, applicationId as string],
        (current: MortgageApplicationClientDto | undefined) => ({
          ...current,
          borrowers: current?.borrowers?.filter((borrower) => borrower.id !== variables.borrowerId),
        })
      );
    },
  });
};

export const useInviteBorrowerMutation = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: async (payload: BorrowerInviteRequest) => {
      const res = await borrowerApi.apiBorrowersBorrowerIdInvitePost(payload);
      return res.data;
    },
    onSuccess: (user, variables) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId], (current: MortgageApplicationClientDto | undefined) => ({
        ...current,
        borrowers: current?.borrowers?.map((borrower) =>
          borrower.id === variables.borrowerId
            ? {
                ...borrower,
                userId: user.id,
                user,
              }
            : borrower
        ),
      }));
    },
  });
};
