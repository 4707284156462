import styled from 'styled-components';

const StyledTooltip = {
  ActionButton: styled.button`
    all: unset;

    position: relative;
    bottom: 12px;

    display: inline-flex;
    cursor: pointer;
    margin: 0 6px;

    font-size: 16px;
  `,
};

export default StyledTooltip;
