import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip as AntdTooltip } from 'antd';
import { useTheme } from 'styled-components';
import Styled from './Tooltip.styled';

type TooltipProps = {
  title: ReactNode;
  'aria-label'?: string;
  id: string;
};

const Tooltip = ({ title, 'aria-label': ariaLabel, id }: TooltipProps) => {
  const { background } = useTheme();

  return (
    <AntdTooltip
      id={id}
      arrowPointAtCenter
      destroyTooltipOnHide
      title={title}
      placement="bottom"
      color={background.primary}
      trigger={['hover', 'click']}
    >
      <Styled.ActionButton aria-describedby={id} aria-label={ariaLabel || 'Tooltip'} role="button" data-testid={id}>
        <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
      </Styled.ActionButton>
    </AntdTooltip>
  );
};

export default Tooltip;
