import { LeadSource } from '@models/enums';
import { getPartnerSource } from 'contexts/partner';
import { createContext, FunctionComponent, ReactNode, useContext } from 'react';

export type PartnerContextType = {
  partner: LeadSource | null;
};

export const PartnerContext = createContext<PartnerContextType>({
  partner: null,
});

export const usePartnerContext = () => useContext(PartnerContext);

export const PartnerProvider: FunctionComponent<{ children: ReactNode; host: string }> = ({ children, host }) => (
  <PartnerContext.Provider value={{ partner: getPartnerSource(host) }}>{children}</PartnerContext.Provider>
);
