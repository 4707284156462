import { createContext, useCallback, useEffect, useRef } from 'react';
import { init, track, getUserId, setUserId, add } from '@amplitude/analytics-browser';
import config from '@config';
import { ENVIRONMENT, LeadSource } from '@models/enums';
import { usePartnerContext } from 'contexts';
import { useUserQuery } from 'hooks/queries/user.hooks';
import { useAuthentication } from '@/lib/auth';
import noop from 'lodash/noop';
import { kebabCase } from 'lodash';
import { AmplitudeEventProperties } from './amplitude.models';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

type AmplitudeContextType = {
  trackEvent: (event: string, properties: Record<string, any>) => void;
};

export const AmplitudeContext = createContext<AmplitudeContextType>({
  trackEvent: noop,
});

export const AmplitudeProvider = ({ children }: { children: React.ReactNode }) => {
  const { partner } = usePartnerContext();
  const { isAuthenticated } = useAuthentication();
  const { data: user, isLoading } = useUserQuery({ disabled: !isAuthenticated });
  const mountRef = useRef(false);

  const trackEvent = useCallback((event: string, properties: AmplitudeEventProperties) => {
    track(event, {
      ...properties,
      ...(properties?.step ? { step: kebabCase(properties.step) } : {}),
      ...(properties?.value ? (typeof properties.value === 'string' ? { value: properties.value } : properties.value) : {}),
    });
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined' || ![ENVIRONMENT.LIVE, ENVIRONMENT.DEV].includes(config.ENVIRONMENT) || mountRef.current) return;

    mountRef.current = true;

    const key = partner === LeadSource.Wealthsimple ? config.AMPLITUDE_KEY_WEALTH_SIMPLE : config.AMPLITUDE_KEY_OTHER;

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0.8, // 80% of users
      forceSessionTracking: true, // if not enabled, an manual event will be required to start the session
    });

    add(sessionReplayTracking);

    init(key, undefined, {
      autocapture: true,
      userId: user?.auth0Id || undefined,
      logLevel: config.ENVIRONMENT === ENVIRONMENT.DEV ? 3 : 0, // 0 is no logging
    });
  }, [isAuthenticated, isLoading, partner, user?.auth0Id]);

  useEffect(() => {
    if (!isAuthenticated || isLoading) return;
    // check if amplitude has a user id associated with the session
    // perform this in a useEffect to ensure we're using the latest email
    const userId = getUserId();

    if (userId !== user?.email) {
      setUserId(user?.email || undefined);
    }
  }, [isAuthenticated, isLoading, user?.email]);

  return <AmplitudeContext.Provider value={{ trackEvent }}>{children}</AmplitudeContext.Provider>;
};
