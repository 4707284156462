import { ENVIRONMENT } from '@models/enums';
import { LeadSource } from '@models/enums';

export const PARTNERS = [LeadSource.Wealthsimple, LeadSource.Money] as const;

export const ENVIRONMENT_PARTNER_SUBDOMAIN_MAPPER: Record<ENVIRONMENT, Partial<Record<LeadSource, string>>> = {
  [ENVIRONMENT.LOCAL]: {
    [LeadSource.Wealthsimple]: LeadSource.Wealthsimple.toLowerCase(),
  },
  [ENVIRONMENT.DEV]: {
    [LeadSource.Wealthsimple]: LeadSource.Money.toLowerCase(),
  },
  [ENVIRONMENT.PILOT]: {
    [LeadSource.Wealthsimple]: LeadSource.Wealthsimple.toLowerCase(),
  },
  [ENVIRONMENT.LIVE]: {
    [LeadSource.Wealthsimple]: LeadSource.Wealthsimple.toLowerCase(),
  },
};
