import { NAMESPACE } from '@models/enums';
import Styled from './CashbackPromotion.styled';
import { useTranslation } from 'next-i18next';
import { useCashbackIncentive } from 'hooks/useCashbackIncentive';
import { useIncentivesMutation } from 'hooks';
import { useCallback } from 'react';
import { useToast } from '@components/Toast/toast.hook';
import Image from 'next/image';
import config from '@config';
import { formatCurrency } from 'utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAmplitude } from 'contexts/amplitude';

const CashbackPromotion = () => {
  const { t } = useTranslation(NAMESPACE.PROMOTION);
  const { enableCashbackIncentive } = useFlags();
  const { cashbackIncentive, interested } = useCashbackIncentive();
  const { mutate: incentiveMutation, isPending } = useIncentivesMutation();
  const { notify } = useToast();
  const { trackEvent } = useAmplitude();

  const handleInterested = useCallback(
    () =>
      incentiveMutation(
        {
          interested: true,
        },
        {
          onSuccess: () => {
            trackEvent('Cashback Ok Im Interested', {
              section: 'ok-im-interested',
              step: 'cashback-incentive-interested',
              interested: true,
            });
            notify(t('CASHBACK_INCENTIVE_TOAST_MESSAGE'), { type: 'success', position: 'bottom-center' });
          },
        }
      ),
    [incentiveMutation, notify, t, trackEvent]
  );

  if (interested === undefined || !enableCashbackIncentive) return null;

  return (
    <Styled.Container>
      <Image src={`${config.IMAGES_BUCKET}cashback-incentive.svg`} alt={t('CASHBACK_INCENTIVE')} width={85} height={65} />
      {!interested ? (
        <Styled.Wrapper>
          <Styled.Title data-testid="cashback-incentive-title">
            {t('CASHBACK_INCENTIVE', { cashback: formatCurrency(cashbackIncentive, { maximumFractionDigits: 0 }) })}
            <span>*</span>
          </Styled.Title>
          <Styled.Description data-testid="cashback-incentive-description">{t('CASHBACK_INCENTIVE_CONTENT')}</Styled.Description>
          <Styled.InterestedButton
            data-testid="cashback-incentive-interested-button"
            color="secondary"
            size="small"
            onClick={handleInterested}
            loading={isPending}
          >
            {t('CASHBACK_INCENTIVE_INTERESTED')}
          </Styled.InterestedButton>
        </Styled.Wrapper>
      ) : (
        <Styled.Wrapper>
          <Styled.InterestedTitle data-testid="cashback-incentive-interested-content">
            {t('CASHBACK_INCENTIVE_INTERESTED_CONTENT')}
          </Styled.InterestedTitle>
        </Styled.Wrapper>
      )}
    </Styled.Container>
  );
};

export default CashbackPromotion;
