import { DealPurpose } from '@pinecorpca/evergreen';
import { useIncentivesQuery, useMortgageAppQuery } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { calculateCashbackIncentive } from 'utils';

export const useCashbackIncentive = () => {
  const { enableCashbackIncentive } = useFlags();
  const { data: application } = useMortgageAppQuery();
  const { data: incentives, isLoading: isIncentivesLoading } = useIncentivesQuery({
    enabled: !!enableCashbackIncentive && !application?.workingWithRealtor && application?.dealPurpose === DealPurpose.PreApproval,
  });

  const cashbackIncentive = useMemo(() => {
    return calculateCashbackIncentive(application?.property?.purchasePrice || 0);
  }, [application?.property?.purchasePrice]);

  return {
    cashbackIncentive,
    interested: incentives?.cashback?.interested,
    isIncentivesLoading,
  };
};
