import { LeadSource } from '@models/enums';
/**
 * getSource is used for middleware (edge functions)
 */
export const getSource = (host: string | undefined | null): LeadSource | null => {
  switch (true) {
    case host?.startsWith(LeadSource.Wealthsimple.toLowerCase()):
    case host?.startsWith(LeadSource.Money.toLowerCase()): // for testing externally
      return LeadSource.Wealthsimple;
    case host?.toLowerCase()?.startsWith(LeadSource.RogersBanks.toLowerCase()): // TODO remove when demo complete https://pinecanada.atlassian.net/browse/CUS-4641
      return LeadSource.RogersBanks;
    default:
      return null;
  }
};
