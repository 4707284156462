import { API } from '@models/enums';
import { useQuery } from '@tanstack/react-query';
import { ratesApi } from 'api/evergreen';

export const useCashbackQuery = () =>
  useQuery({
    queryKey: [API.CASHBACK],
    queryFn: async () => {
      const { data } = await ratesApi.apiPublicWsCashbackDiscountsGet();
      return data;
    },
  });
