import { API } from '@models/enums';
import { IncentivesCashbackInput } from '@pinecorpca/evergreen';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { incentivesApi } from 'api/evergreen';
import { useRouter } from 'next/router';

export const useIncentivesQuery = ({ enabled }: { enabled: boolean }) => {
  const {
    query: { applicationId },
  } = useRouter();
  return useQuery({
    queryKey: [API.INCENTIVES, applicationId],
    queryFn: async () => {
      const { data } = await incentivesApi.apiMortgageApplicationsApplicationIdIncentivesGet({ applicationId: applicationId as string });
      return data;
    },
    enabled,
  });
};

export const useIncentivesMutation = () => {
  const {
    query: { applicationId },
  } = useRouter();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: IncentivesCashbackInput & { applicationId?: string }) =>
      incentivesApi.apiMortgageApplicationsApplicationIdIncentivesCashbackPost({
        applicationId: payload?.applicationId || (applicationId as string),
        incentivesCashbackInput: payload,
      }),
    onSuccess: ({ data }) =>
      queryClient.setQueryData([API.INCENTIVES, applicationId], {
        applicationId: data?.state?.applicationId as string,
        cashback: {
          interested: data.state?.interested,
        },
      }),
  });
};
